import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const SohbetPage = ({data, location}) => {
    const posts = data.allMarkdownRemark.nodes
    const { siteUrl, defaultImage } = useSiteMetadata();

    return (
        <Layout>
            <GatsbySeo
                title="Tasavvuf Sohbetleri"
                description="Kazım Yardımcı'nın sesinden şiirler ve gazeller."
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: "Tasavvuf Sohbetleri",
                    images: [
                        {
                            url: `${siteUrl}${defaultImage}`,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>Tasavvuf Sohbetleri</h1>
                    <span>Kazım Yardımcı'nın sesinden şiirler ve gazeller</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap pt-5">
                    <div className="container clearfix">

                        <div className="row gutter-40 col-mb-80">

                            {posts.map(post => {
                                return (
                                    <div className="col-lg-4 col-md-6 bottommargin-sm" key={post.frontmatter.title}>
                                        <div className="feature-box media-box fbox-bg">
                                            <Link to={post.frontmatter.slug}>

                                            <div className="fbox-media">
                                                    <img src={(post.frontmatter.featuredImage && post.frontmatter.featuredImage.childImageSharp.fluid.src) || defaultImage} alt={post.frontmatter.title}/>
                                            </div>
                                            <div className="fbox-content border-0">
                                                <h3 className="nott ls0 font-weight-semibold">{post.frontmatter.title}</h3>
                                            </div>
                                            </Link>

                                        </div>
                                    </div>
                                    )
                            })}


                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default SohbetPage

export const pageQuery = graphql`
  query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/content/tasavvuf-sohbetleri/"  }}
        sort: { fields: [frontmatter___no], order: ASC }
      ) {
      nodes {
        frontmatter {
          title
          slug
          featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        }
      }
    }
  }
`
